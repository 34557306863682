<template>
  <div>
    <h1>{{$route.params.instance}}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
              rounded
              color="primary"
              dark
              @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('Product') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="products"
        :search="search"
        :items-per-page="itemsPerPage"
        @update:options="updateOptions"
        class="elevation-1"
        :hide-default-footer="products.length < 5"
        :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.author="{ item }">
          <v-avatar
              v-if="item.author"
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{item.author.firstName | uppercaseFirstLetter}}{{item.author.lastName | uppercaseFirstLetter}}</v-avatar>
          <template v-if="item.author">{{item.author.firstName}} {{item.author.lastName}}</template>
        </template>
        <template v-slot:item.createdAt="{item}" class="ml-0">
          {{item.createdAt | formatLocaleDate }}
        </template>
        <template v-slot:item.updatedAt="{item}" class="ml-0">
          {{item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="copyItem(item)"
          >
            mdi-content-copy
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <product-edit-dialog ref="productEditDialog" @updated="fetchProducts"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import ProductEditDialog from '@/components/ProductEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";

export default {
  name: 'ProductList',
  mixins: [vuetifyTableMixin],
  components: { ProductEditDialog, ConfirmDialog },
  data () {
    return {
      products: [],
      search: ''
    }
  },
  computed:{
    headers(){ return [
      {
        text: this.$t('Product name'),
        align: 'start',
        value: 'name',
      },
      {
        text: this.$t('Product number'),
        value: 'number',
      },
      {
        text: this.$t('Author'),
        value: 'author',
        sortable: true
      },
      {
        text: this.$t('Created on'),
        value: 'createdAt',
        sortable: true,
      },
      {
        text: this.$t('Last modified on'),
        value: 'updatedAt',
        sortable: true,
      },

      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'end',
        width: 120,
      }
    ]},
  },
  methods: {
    copyItem(product) {
      APIService.getProduct(product).then(product => {
        product.name = `${product.name} Copy`
        delete product.id
        delete product.createdAt
        delete product.updatedAt
        delete product.author
        delete product.lastUpdatedBy
        this.$refs.productEditDialog.edit(product)
      })
    },
    createUser() {
      this.$refs.productEditDialog.create(this.$route.params.instance)
    },
    editItem(product) {
      this.$refs.productEditDialog.edit({...product})
    },
    async deleteItem(product) {
      await this.$refs.confirmDelete.show({
        title: this.$t('Remove product'),
        text: this.$t('')+" "+product.name,
        confirm: this.$t('remove')
      })
      await APIService.deleteProduct(product)
      await this.fetchProducts()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchProducts() {
       this.products = await APIService.getProducts()
    }
  },
  async beforeRouteEnter(to, from, next) {
    const products = await APIService.getProducts()
    next(vm => vm.products = products)
  },
  async beforeRouteUpdate(to,from,next) {
    this.products = await APIService.getProducts()
    next()
  }
}
</script>

<style scoped>

</style>
