<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div class="headline">{{ $t('User License assignment') }}</div>
        </v-card-title>
        <v-card-text>
          <div>{{ $t('License number') }}: {{licence.id}}</div>
          <div>{{ $tc('Customer', 1) }}: {{licence.owner.email}}</div>
          <div>{{ $t('Product') }}: {{licence.product.name}}</div>

          <div>{{ $t('Licenses') }}: {{quantityTotal}}</div>
          <div>{{  $t('Available licenses') }}: {{quantityRemaining}}</div>
          <v-form ref="productForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="text-h5 mb-2">{{  $t('Assignment') }}:</div>
                  <user-select
                      :value.sync="assignments"
                      :users="user && user.role === 'admin' ? users : teamMembers"
                      :label="$t('Participants')"
                      multiple
                      @removeItem="removeUser"
                  />
                </v-col>
                <v-col cols="8">
                  <v-select class="my-0 py-0" v-model="membersToAdd" :items="groups" :label="this.$t('Add members to a group')" item-text="name" item-value="members" :no-data-text="$t('No group available')"/>
                </v-col>
                <v-col cols="4">
                <v-btn :disabled="!membersToAdd" color="primary" @click="addMembers">{{  $t('Add') }}</v-btn>
                </v-col>
                <v-col cols="8">
                  <v-select class="my-0 py-0" v-model="membersToRemove" :items="groups" :label="this.$t('Remove members from a group')" item-text="name" item-value="members" :no-data-text="$t('No group available')"/>
                </v-col>
                <v-col cols="4">
                  <v-btn :disabled="!membersToRemove" color="primary" @click="removeMembers">{{  $t('Remove') }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveProduct"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
    <v-snackbar
        v-model="saveSuccess"
        color="success"
        multi-line="multi-line"
        timeout="2000"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import UserSelect from '@/components/UserSelect'
import Snackbar from "@/components/Snackbar";

const LICENCE_INIT_DATA = {
  assignments: [],
  product: {},
  owner: {}
}
export default {
  name: 'LicenceAssignDialog',
  components: {Snackbar, ConfirmDialog, UserSelect },
  props: ['userId'],
  data() {
    return {
      membersToAdd: undefined,
      quantityTotal: 0,
      assignments: [],
      originalAssignments: [],
      membersAdded: [],
      membersRemoved: [],
      groups: [],
      membersToRemove: undefined,
      teamMembers: [],
      showDialog: false,
      values: [],
      value: null,
      licence: LICENCE_INIT_DATA,
      saveSuccess: false,
      isProductNameTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: this.$t('License assignment saved successfully'),
        icon: 'mdi-check-circle',
        color: 'success'
      },
    }
  },
  methods: {
    addMembers() {

      this.assignments = [...new Set([...this.assignments, ...this.membersToAdd])]
      this.membersToAdd = undefined
    },
    removeMembers() {
      this.assignments = this.assignments.filter(assignment => !this.membersToRemove.includes(assignment))
      this.membersToRemove = undefined
    },
    calculateChanges() {  // new
      this.membersAdded = this.assignments.filter(x => !this.originalAssignments.includes(x));
      this.membersRemoved = this.originalAssignments.filter(x => !this.assignments.includes(x));
    },
    async edit(licence) {
      this.licence = licence
      this.quantityTotal = licence.quantityTotal
      if(this.user.role === 'user') {
        this.teamMembers = await APIService.getTeamMembers( this.userId)
        this.groups = await APIService.getUserGroups( this.userId)
        this.teamMembers.push(this.user)
      }
      this.assignments = await APIService.getAssignments(licence.id)
      this.originalAssignments = [...this.assignments];
      this.showDialog = true
    },
    close() {
      this.licence = LICENCE_INIT_DATA
      this.$refs.productForm.resetValidation()
      this.showDialog = false
    },
    async saveProduct () {
      if (!this.$refs.productForm.validate()) {
        return
      }
      // if(!this.product.id && await APIService.isProductNameTaken(this.product.name, this.product.instance)) {
      //   this.isProductNameTaken = true
      //   return
      // }
      await APIService.updateAssignments(this.licence.id, this.assignments)
      this.calculateChanges();
      // Logging added members
      console.log("Added members:");
      for (const member of this.membersAdded) {
        console.log(member);
        await APIService.sendEvent("addLicenseAssignment", member, "", "", "", this.licence.id)
      }

      // Logging removed members
      console.log("Removed members:");
      for (const member of this.membersRemoved) {
        console.log(member);
        await APIService.sendEvent("removeLicenseAssignment", member, "", "", "", this.licence.id)
      }

      this.showDialog = false
      this.$emit('updated')
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.close()
    },
    removeUser(item) {
      const index = this.assignments.indexOf(item.id)
      if (index >= 0) this.assignments.splice(index, 1)
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} `+this.$t('mandatory')]
    },
  },
  computed: {
    ...mapState(['users', 'user']),
    quantityRemaining() {
      return this.quantityTotal - this.assignments.length
    }
  },
  watch: {
    "assignments"() {
        if(this.quantityRemaining < 0) {
          this.assignments.pop()
        }
    }
  }
}
</script>

<style scoped>

</style>
