<template>
  <div>
    <h1>{{$route.params.instance}}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ this.$tc('License', 2) }}
            </v-btn>
            <v-btn
                rounded
                color="success"
                dark
                @click="createCSV"
                style="margin-left:10px"
            >
              <v-icon left>mdi-download</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="licences"
          @current-items="getFiltered"
          ref="licenceTable"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="licences.length < 5"
          :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.dateOfPurchase="{item}">
          {{ item.dateOfPurchase | formatLocaleDate }}
        </template>
        <template v-slot:item.owner="{item}">
          {{ item.owner.firstName }} {{ item.owner.lastName }}
        </template>
        <template v-slot:item.lastUpdatedBy="{item}">
          {{ (item.lastUpdatedBy && item.lastUpdatedBy.firstName) ? item.lastUpdatedBy.firstName : "" }} {{ (item.lastUpdatedBy && item.lastUpdatedBy.lastName) ? item.lastUpdatedBy.lastName : "" }}
        </template>
        <template v-slot:item.expirationDate="{item}">
          <v-chip
              dark v-if="item.expirationDate"
              :color="isExpired(item.expirationDate) ?  'red' : 'green'"
          >
            {{ item.expirationDate | formatLocaleDate }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              v-if="!item.partner"
              small
              class="mr-2"
              @click="editLicenceAssignment(item)"

          >
            mdi-account-plus
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <licence-edit-dialog ref="licencesEditDialog" @updated="fetchLicences"/>
    <licence-assign-dialog ref="licencesAssignDialog"/>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import Papa from 'papaparse'
import LicenceEditDialog from '@/components/LicenceEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import LicenceAssignDialog from "@/components/LicenceAssignDialog";
import {isAfter, parseISO, startOfToday} from "date-fns";

export default {
  name: 'LicenceList',
  mixins: [vuetifyTableMixin],
  components: { LicenceEditDialog, ConfirmDialog, LicenceAssignDialog },
  data () {
    return {
      licences: [],
      filteredLicences: [],
      search: '',
      partners: [],
      showLoader: false
    }
  },
  computed:{
    headers(){ return [
      {
        text: this.$t('firstName'),
        sortable: true,
        value: 'owner.firstName',
      },
      {
        text: this.$t('lastName'),
        sortable: true,
        value: 'owner.lastName',
      },
      {
        text: this.$t('email'),
        sortable: true,
        value: 'owner.email',
        align: ' d-none'
      },
      {
        text: this.$t('Company'),
        sortable: true,
        value: 'owner.companyName',
      },
      {
        text: this.$t('Last modified from'),
        sortable: true,
        value: "lastUpdatedBy",
      },
      {
        text: this.$t('Partner'),
        sortable: true,
        value: 'partner.companyName',
      },
      {
        text:  this.$t('Expiry Date'),
        sortable: true,
        value: 'expirationDate',
      },
      {
        text: this.$t('Order number'),
        sortable: true,
        value: 'orderNumber',
      },
      {
        text: this.$t('Product number'),
        sortable: true,
        value: 'product.number',
      },
      {
        text: this.$t('Product'),
        value: 'product.name',
        sortable: false
      },
      {
        text: this.$t('Amount'),
        value: 'quantityTotal',
        sortable: false,
        align: 'end'
      },
      {
        text: this.$t('Created on'),
        value: 'dateOfPurchase',
        sortable: true,
        align: 'end',
        width: 120,
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'end',
        width: 100,
      }
    ]},
  },
  methods: {
    getFiltered(){
      this.filteredLicences = this.$refs.licenceTable.$children[0].filteredItems;
    },
    createCSV(){
      let csvData = [];
      csvData.push([]);
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }

      for(let i=0; i < this.filteredLicences.length; i++){
        let current = this.filteredLicences[i];
        let row = [current.owner ? current.owner.firstName : "", current.owner ? current.owner.lastName : "", current.owner ? current.owner.email : "", current.owner ? current.owner.companyName : "",  ((current.lastUpdatedBy && current.lastUpdatedBy.firstName) ? current.lastUpdatedBy.firstName : "") + " " + ((current.lastUpdatedBy && current.lastUpdatedBy.lastName) ? current.lastUpdatedBy.lastName : ""), current.partner ? current.partner.companyName : "", current.expirationDate, current.orderNumber, current.product ? current.product.number : "", current.product ? current.product.name : "", current.quantityTotal, current.dateOfPurchase, ""];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'lizenzen.csv';
      anchor.click();
    },
    createUser() {
      this.$refs.licencesEditDialog.create()
    },
    editItem(licence) {
      this.$refs.licencesEditDialog.edit({...licence})
    },
    editLicenceAssignment(licence) {
      this.$refs.licencesAssignDialog.edit({...licence})
    },
    isExpired(date) {
      if(!date) {
        return false;
      }
      return isAfter(startOfToday(),  parseISO(date))
    },
    async deleteItem(licence) {
      await this.$refs.confirmDelete.show({
        title: this.$t('Remove license'),
        text: this.$t('Do you want to remove this license?')+" "+licence.id,
        confirm: this.$t('remove')
      })
      await APIService.deleteLicence(licence)
      await this.fetchLicences()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchLicences() {

      this.showLoader = true;
      this.licences = await APIService.getLicences()
      this.showLoader = false;
    },
  },
  async beforeRouteUpdate(to,from,next) {
    //await this.fetchLicences()
    next()
  },
  async mounted() {
    await this.fetchLicences();
    this.filteredLicences = this.licences;
  }
}

</script>

<style lang="scss" scoped>
</style>
