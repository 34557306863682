<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div class="headline">{{ $t('User License assignment') }}</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="font-weight-bold" cols="6" >Titel</v-col>
              <v-col class="font-weight-bold" cols="6" >Kurs-ID</v-col>
              <template  v-for="course in courses">
                <v-col class="pb-0 pt-0" cols="6" :key="course.id+'_title'">{{ course.title }}</v-col>
                <v-col class="pb-0 pt-0" cols="6" :key="course.id+'_id'">{{ course.id }}</v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from "@/components/Snackbar";


export default {
  name: 'LicenceAssignDialog',
  components: {Snackbar, ConfirmDialog},
  props: ['userId'],
  data() {
    return {
      courses: undefined,
      showDialog: false,
    }
  },
  methods: {
    async show(courses) {
      this.courses = courses
      this.showDialog = true
    },
    close() {
      this.showDialog = false
    }
  }
}
</script>

<style scoped>

</style>
