<template>
  <div>
    <h1>{{ userId === $store.state.user.id ? $t('My Licenses') : $t('Licences') }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="licences"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="licences.length < 5"
      >
        <template v-slot:item.dateOfPurchase="{item}">
          {{ item.dateOfPurchase | formatLocaleDate }}
        </template>
        <template v-slot:item.expirationDate="{item}">
          {{ item.expirationDate | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              v-if="!item.partner"
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-account-plus
          </v-icon>
          <v-icon
              v-if="user.role ==='partner'"
              small
              class="mr-2"
              @click="showCoursesForLicence(item)"
          >
            mdi-school
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <licence-assign-dialog ref="licencesAssignDialog" :user-id="userId" @updated="fetchLicences"/>
    <product-courses-dialog ref="productCourseDialog"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import LicenceAssignDialog from '@/components/LicenceAssignDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapState} from "vuex";
import ProductCoursesDialog from '@/components/ProductCoursesDialog.vue';

export default {
  name: 'LicenceList',
  mixins: [vuetifyTableMixin],
  components: {LicenceAssignDialog, ConfirmDialog, ProductCoursesDialog},
  props: ['userId'],
  data() {
    return {
      licences: [],
      search: ''
    }
  },
  computed: {
    ...mapState(['user']),
    headers() {
      return [
        {
          text: this.$t('License number'),
          value: 'id',
          sortable: false
        },
        {
          text: 'Produkt',
          value: 'product.name',
          sortable: false
        },
        {
          text: this.$t('Amount'),
          value: 'quantityTotal',
          sortable: false,
          align: 'end'
        },
        {
          text: this.$t('Left'),
          value: 'quantityRemaining',
          sortable: false,
          align: 'end'
        },
        {
          text: this.$t('Date of purchase'),
          value: 'dateOfPurchase',
          sortable: false,
          align: 'end'
        },
        {
          text: this.$t('Expiry Date'),
          sortable: true,
          value: 'expirationDate',
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    },
  },
  methods: {
    createUser() {
      this.$refs.licencesAssignDialog.create()
    },
    editItem(licence) {
      this.$refs.licencesAssignDialog.edit({...licence})
    },
    async showCoursesForLicence(licence) {
      const courses = await APIService.getCoursesForLicence(licence)
      console.log("Courses", courses)
      this.$refs.productCourseDialog.show(courses)
    },
    async deleteItem(licence) {
      await this.$refs.confirmDelete.show({
        title: this.$t('Remove license'),
        text: this.$t('Do you want to remove this license?') + " " + licence.id,
        confirm: this.$t('remove')
      })
      await APIService.deleteLicence(licence)
      await this.fetchLicences()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchLicences() {
      this.licences = await APIService.getUserLicences(this.userId)
    }
  },
  async beforeRouteEnter(to, from, next) {
    const licences = await APIService.getUserLicences(to.params.userId)
    next(vm => vm.licences = licences)
  },
  async beforeRouteUpdate(to, from, next) {
    this.licences = await APIService.getUserLicences(to.params.userId)
    next()
  }
}
</script>

<style scoped>

</style>
